/**
 * Mapping of font awesome symbols to names sutiable for usage with FontAwesome component.
 */

const FA = {
	_500px: '500px',
	address_book: 'address-book',
	address_book_o: 'address-book-o',
	address_card: 'address-card',
	address_card_o: 'address-card-o',
	adjust: 'adjust',
	adn: 'adn',
	align_center: 'align-center',
	align_justify: 'align-justify',
	align_left: 'align-left',
	align_right: 'align-right',
	amazon: 'amazon',
	ambulance: 'ambulance',
	american_sign_language_interpreting: 'american-sign-language-interpreting',
	anchor: 'anchor',
	android: 'android',
	angellist: 'angellist',
	angle_double_down: 'angle-double-down',
	angle_double_left: 'angle-double-left',
	angle_double_right: 'angle-double-right',
	angle_double_up: 'angle-double-up',
	angle_down: 'angle-down',
	angle_left: 'angle-left',
	angle_right: 'angle-right',
	angle_up: 'angle-up',
	apple: 'apple',
	archive: 'archive',
	area_chart: 'area-chart',
	arrow_circle_down: 'arrow-circle-down',
	arrow_circle_left: 'arrow-circle-left',
	arrow_circle_o_down: 'arrow-circle-o-down',
	arrow_circle_o_left: 'arrow-circle-o-left',
	arrow_circle_o_right: 'arrow-circle-o-right',
	arrow_circle_o_up: 'arrow-circle-o-up',
	arrow_circle_right: 'arrow-circle-right',
	arrow_circle_up: 'arrow-circle-up',
	arrow_down: 'arrow-down',
	arrow_left: 'arrow-left',
	arrow_right: 'arrow-right',
	arrow_up: 'arrow-up',
	arrows: 'arrows',
	arrows_alt: 'arrows-alt',
	arrows_h: 'arrows-h',
	arrows_v: 'arrows-v',
	asl_interpreting: 'asl-interpreting',
	assistive_listening_systems: 'assistive-listening-systems',
	asterisk: 'asterisk',
	at: 'at',
	audio_description: 'audio-description',
	automobile: 'automobile',
	backward: 'backward',
	balance_scale: 'balance-scale',
	ban: 'ban',
	bandcamp: 'bandcamp',
	bank: 'bank',
	bar_chart: 'bar-chart',
	bar_chart_o: 'bar-chart-o',
	barcode: 'barcode',
	bars: 'bars',
	bath: 'bath',
	bathtub: 'bathtub',
	battery: 'battery',
	battery_0: 'battery-0',
	battery_1: 'battery-1',
	battery_2: 'battery-2',
	battery_3: 'battery-3',
	battery_4: 'battery-4',
	battery_empty: 'battery-empty',
	battery_full: 'battery-full',
	battery_half: 'battery-half',
	battery_quarter: 'battery-quarter',
	battery_three_quarters: 'battery-three-quarters',
	bed: 'bed',
	beer: 'beer',
	behance: 'behance',
	behance_square: 'behance-square',
	bell: 'bell',
	bell_o: 'bell-o',
	bell_slash: 'bell-slash',
	bell_slash_o: 'bell-slash-o',
	bicycle: 'bicycle',
	binoculars: 'binoculars',
	birthday_cake: 'birthday-cake',
	bitbucket: 'bitbucket',
	bitbucket_square: 'bitbucket-square',
	bitcoin: 'bitcoin',
	black_tie: 'black-tie',
	blind: 'blind',
	bluetooth: 'bluetooth',
	bluetooth_b: 'bluetooth-b',
	bold: 'bold',
	bolt: 'bolt',
	bomb: 'bomb',
	book: 'book',
	bookmark: 'bookmark',
	bookmark_o: 'bookmark-o',
	braille: 'braille',
	briefcase: 'briefcase',
	btc: 'btc',
	bug: 'bug',
	building: 'building',
	building_o: 'building-o',
	bullhorn: 'bullhorn',
	bullseye: 'bullseye',
	bus: 'bus',
	buysellads: 'buysellads',
	cab: 'cab',
	calculator: 'calculator',
	calendar: 'calendar',
	calendar_check_o: 'calendar-check-o',
	calendar_minus_o: 'calendar-minus-o',
	calendar_o: 'calendar-o',
	calendar_plus_o: 'calendar-plus-o',
	calendar_times_o: 'calendar-times-o',
	camera: 'camera',
	camera_retro: 'camera-retro',
	car: 'car',
	caret_down: 'caret-down',
	caret_left: 'caret-left',
	caret_right: 'caret-right',
	caret_square_o_down: 'caret-square-o-down',
	caret_square_o_left: 'caret-square-o-left',
	caret_square_o_right: 'caret-square-o-right',
	caret_square_o_up: 'caret-square-o-up',
	caret_up: 'caret-up',
	cart_arrow_down: 'cart-arrow-down',
	cart_plus: 'cart-plus',
	cc: 'cc',
	cc_amex: 'cc-amex',
	cc_diners_club: 'cc-diners-club',
	cc_discover: 'cc-discover',
	cc_jcb: 'cc-jcb',
	cc_mastercard: 'cc-mastercard',
	cc_paypal: 'cc-paypal',
	cc_stripe: 'cc-stripe',
	cc_visa: 'cc-visa',
	certificate: 'certificate',
	chain: 'chain',
	chain_broken: 'chain-broken',
	check: 'check',
	check_circle: 'check-circle',
	check_circle_o: 'check-circle-o',
	check_square: 'check-square',
	check_square_o: 'check-square-o',
	chevron_circle_down: 'chevron-circle-down',
	chevron_circle_left: 'chevron-circle-left',
	chevron_circle_right: 'chevron-circle-right',
	chevron_circle_up: 'chevron-circle-up',
	chevron_down: 'chevron-down',
	chevron_left: 'chevron-left',
	chevron_right: 'chevron-right',
	chevron_up: 'chevron-up',
	child: 'child',
	chrome: 'chrome',
	circle: 'circle',
	circle_o: 'circle-o',
	circle_o_notch: 'circle-o-notch',
	circle_thin: 'circle-thin',
	clipboard: 'clipboard',
	clock_o: 'clock-o',
	clone: 'clone',
	close: 'close',
	cloud: 'cloud',
	cloud_download: 'cloud-download',
	cloud_upload: 'cloud-upload',
	cny: 'cny',
	code: 'code',
	code_fork: 'code-fork',
	codepen: 'codepen',
	codiepie: 'codiepie',
	coffee: 'coffee',
	cog: 'cog',
	cogs: 'cogs',
	columns: 'columns',
	comment: 'comment',
	comment_o: 'comment-o',
	commenting: 'commenting',
	commenting_o: 'commenting-o',
	comments: 'comments',
	comments_o: 'comments-o',
	compass: 'compass',
	compress: 'compress',
	connectdevelop: 'connectdevelop',
	contao: 'contao',
	copy: 'copy',
	copyright: 'copyright',
	creative_commons: 'creative-commons',
	credit_card: 'credit-card',
	credit_card_alt: 'credit-card-alt',
	crop: 'crop',
	crosshairs: 'crosshairs',
	css3: 'css3',
	cube: 'cube',
	cubes: 'cubes',
	cut: 'cut',
	cutlery: 'cutlery',
	dashboard: 'dashboard',
	dashcube: 'dashcube',
	database: 'database',
	deaf: 'deaf',
	deafness: 'deafness',
	dedent: 'dedent',
	delicious: 'delicious',
	desktop: 'desktop',
	deviantart: 'deviantart',
	diamond: 'diamond',
	digg: 'digg',
	dollar: 'dollar',
	dot_circle_o: 'dot-circle-o',
	download: 'download',
	dribbble: 'dribbble',
	drivers_license: 'drivers-license',
	drivers_license_o: 'drivers-license-o',
	dropbox: 'dropbox',
	drupal: 'drupal',
	edge: 'edge',
	edit: 'edit',
	eercast: 'eercast',
	eject: 'eject',
	ellipsis_h: 'ellipsis-h',
	ellipsis_v: 'ellipsis-v',
	empire: 'empire',
	envelope: 'envelope',
	envelope_o: 'envelope-o',
	envelope_open: 'envelope-open',
	envelope_open_o: 'envelope-open-o',
	envelope_square: 'envelope-square',
	envira: 'envira',
	eraser: 'eraser',
	etsy: 'etsy',
	eur: 'eur',
	euro: 'euro',
	exchange: 'exchange',
	exclamation: 'exclamation',
	exclamation_circle: 'exclamation-circle',
	exclamation_triangle: 'exclamation-triangle',
	expand: 'expand',
	expeditedssl: 'expeditedssl',
	external_link: 'external-link',
	external_link_square: 'external-link-square',
	eye: 'eye',
	eye_slash: 'eye-slash',
	eyedropper: 'eyedropper',
	fa: 'fa',
	facebook: 'facebook',
	facebook_f: 'facebook-f',
	facebook_official: 'facebook-official',
	facebook_square: 'facebook-square',
	fast_backward: 'fast-backward',
	fast_forward: 'fast-forward',
	fax: 'fax',
	feed: 'feed',
	female: 'female',
	fighter_jet: 'fighter-jet',
	file: 'file',
	file_archive_o: 'file-archive-o',
	file_audio_o: 'file-audio-o',
	file_code_o: 'file-code-o',
	file_excel_o: 'file-excel-o',
	file_image_o: 'file-image-o',
	file_movie_o: 'file-movie-o',
	file_o: 'file-o',
	file_pdf_o: 'file-pdf-o',
	file_photo_o: 'file-photo-o',
	file_picture_o: 'file-picture-o',
	file_powerpoint_o: 'file-powerpoint-o',
	file_sound_o: 'file-sound-o',
	file_text: 'file-text',
	file_text_o: 'file-text-o',
	file_video_o: 'file-video-o',
	file_word_o: 'file-word-o',
	file_zip_o: 'file-zip-o',
	files_o: 'files-o',
	film: 'film',
	filter: 'filter',
	fire: 'fire',
	fire_extinguisher: 'fire-extinguisher',
	firefox: 'firefox',
	first_order: 'first-order',
	flag: 'flag',
	flag_checkered: 'flag-checkered',
	flag_o: 'flag-o',
	flash: 'flash',
	flask: 'flask',
	flickr: 'flickr',
	floppy_o: 'floppy-o',
	folder: 'folder',
	folder_o: 'folder-o',
	folder_open: 'folder-open',
	folder_open_o: 'folder-open-o',
	font: 'font',
	font_awesome: 'font-awesome',
	fonticons: 'fonticons',
	fort_awesome: 'fort-awesome',
	forumbee: 'forumbee',
	forward: 'forward',
	foursquare: 'foursquare',
	free_code_camp: 'free-code-camp',
	frown_o: 'frown-o',
	futbol_o: 'futbol-o',
	gamepad: 'gamepad',
	gavel: 'gavel',
	gbp: 'gbp',
	ge: 'ge',
	gear: 'gear',
	gears: 'gears',
	genderless: 'genderless',
	get_pocket: 'get-pocket',
	gg: 'gg',
	gg_circle: 'gg-circle',
	gift: 'gift',
	git: 'git',
	git_square: 'git-square',
	github: 'github',
	github_alt: 'github-alt',
	github_square: 'github-square',
	gitlab: 'gitlab',
	gittip: 'gittip',
	glass: 'glass',
	glide: 'glide',
	glide_g: 'glide-g',
	globe: 'globe',
	google: 'google',
	google_plus: 'google-plus',
	google_plus_circle: 'google-plus-circle',
	google_plus_official: 'google-plus-official',
	google_plus_square: 'google-plus-square',
	google_wallet: 'google-wallet',
	graduation_cap: 'graduation-cap',
	gratipay: 'gratipay',
	grav: 'grav',
	group: 'group',
	h_square: 'h-square',
	hacker_news: 'hacker-news',
	hand_grab_o: 'hand-grab-o',
	hand_lizard_o: 'hand-lizard-o',
	hand_o_down: 'hand-o-down',
	hand_o_left: 'hand-o-left',
	hand_o_right: 'hand-o-right',
	hand_o_up: 'hand-o-up',
	hand_paper_o: 'hand-paper-o',
	hand_peace_o: 'hand-peace-o',
	hand_pointer_o: 'hand-pointer-o',
	hand_rock_o: 'hand-rock-o',
	hand_scissors_o: 'hand-scissors-o',
	hand_spock_o: 'hand-spock-o',
	hand_stop_o: 'hand-stop-o',
	handshake_o: 'handshake-o',
	hard_of_hearing: 'hard-of-hearing',
	hashtag: 'hashtag',
	hdd_o: 'hdd-o',
	header: 'header',
	headphones: 'headphones',
	heart: 'heart',
	heart_o: 'heart-o',
	heartbeat: 'heartbeat',
	history: 'history',
	home: 'home',
	hospital_o: 'hospital-o',
	hotel: 'hotel',
	hourglass: 'hourglass',
	hourglass_1: 'hourglass-1',
	hourglass_2: 'hourglass-2',
	hourglass_3: 'hourglass-3',
	hourglass_end: 'hourglass-end',
	hourglass_half: 'hourglass-half',
	hourglass_o: 'hourglass-o',
	hourglass_start: 'hourglass-start',
	houzz: 'houzz',
	html5: 'html5',
	i_cursor: 'i-cursor',
	id_badge: 'id-badge',
	id_card: 'id-card',
	id_card_o: 'id-card-o',
	ils: 'ils',
	image: 'image',
	imdb: 'imdb',
	inbox: 'inbox',
	indent: 'indent',
	industry: 'industry',
	info: 'info',
	info_circle: 'info-circle',
	inr: 'inr',
	instagram: 'instagram',
	institution: 'institution',
	internet_explorer: 'internet-explorer',
	intersex: 'intersex',
	ioxhost: 'ioxhost',
	italic: 'italic',
	joomla: 'joomla',
	jpy: 'jpy',
	jsfiddle: 'jsfiddle',
	key: 'key',
	keyboard_o: 'keyboard-o',
	krw: 'krw',
	language: 'language',
	laptop: 'laptop',
	lastfm: 'lastfm',
	lastfm_square: 'lastfm-square',
	leaf: 'leaf',
	leanpub: 'leanpub',
	legal: 'legal',
	lemon_o: 'lemon-o',
	level_down: 'level-down',
	level_up: 'level-up',
	life_bouy: 'life-bouy',
	life_buoy: 'life-buoy',
	life_ring: 'life-ring',
	life_saver: 'life-saver',
	lightbulb_o: 'lightbulb-o',
	line_chart: 'line-chart',
	link: 'link',
	linkedin: 'linkedin',
	linkedin_square: 'linkedin-square',
	linode: 'linode',
	linux: 'linux',
	list: 'list',
	list_alt: 'list-alt',
	list_ol: 'list-ol',
	list_ul: 'list-ul',
	location_arrow: 'location-arrow',
	lock: 'lock',
	long_arrow_down: 'long-arrow-down',
	long_arrow_left: 'long-arrow-left',
	long_arrow_right: 'long-arrow-right',
	long_arrow_up: 'long-arrow-up',
	low_vision: 'low-vision',
	magic: 'magic',
	magnet: 'magnet',
	mail_forward: 'mail-forward',
	mail_reply: 'mail-reply',
	mail_reply_all: 'mail-reply-all',
	male: 'male',
	map: 'map',
	map_marker: 'map-marker',
	map_o: 'map-o',
	map_pin: 'map-pin',
	map_signs: 'map-signs',
	mars: 'mars',
	mars_double: 'mars-double',
	mars_stroke: 'mars-stroke',
	mars_stroke_h: 'mars-stroke-h',
	mars_stroke_v: 'mars-stroke-v',
	maxcdn: 'maxcdn',
	meanpath: 'meanpath',
	medium: 'medium',
	medkit: 'medkit',
	meetup: 'meetup',
	meh_o: 'meh-o',
	mercury: 'mercury',
	microchip: 'microchip',
	microphone: 'microphone',
	microphone_slash: 'microphone-slash',
	minus: 'minus',
	minus_circle: 'minus-circle',
	minus_square: 'minus-square',
	minus_square_o: 'minus-square-o',
	mixcloud: 'mixcloud',
	mobile: 'mobile',
	mobile_phone: 'mobile-phone',
	modx: 'modx',
	money: 'money',
	moon_o: 'moon-o',
	mortar_board: 'mortar-board',
	motorcycle: 'motorcycle',
	mouse_pointer: 'mouse-pointer',
	music: 'music',
	navicon: 'navicon',
	neuter: 'neuter',
	newspaper_o: 'newspaper-o',
	object_group: 'object-group',
	object_ungroup: 'object-ungroup',
	odnoklassniki: 'odnoklassniki',
	odnoklassniki_square: 'odnoklassniki-square',
	opencart: 'opencart',
	openid: 'openid',
	opera: 'opera',
	optin_monster: 'optin-monster',
	outdent: 'outdent',
	pagelines: 'pagelines',
	paint_brush: 'paint-brush',
	paper_plane: 'paper-plane',
	paper_plane_o: 'paper-plane-o',
	paperclip: 'paperclip',
	paragraph: 'paragraph',
	paste: 'paste',
	pause: 'pause',
	pause_circle: 'pause-circle',
	pause_circle_o: 'pause-circle-o',
	paw: 'paw',
	paypal: 'paypal',
	pencil: 'pencil',
	pencil_square: 'pencil-square',
	pencil_square_o: 'pencil-square-o',
	percent: 'percent',
	phone: 'phone',
	phone_square: 'phone-square',
	photo: 'photo',
	picture_o: 'picture-o',
	pie_chart: 'pie-chart',
	pied_piper: 'pied-piper',
	pied_piper_alt: 'pied-piper-alt',
	pied_piper_pp: 'pied-piper-pp',
	pinterest: 'pinterest',
	pinterest_p: 'pinterest-p',
	pinterest_square: 'pinterest-square',
	plane: 'plane',
	play: 'play',
	play_circle: 'play-circle',
	play_circle_o: 'play-circle-o',
	plug: 'plug',
	plus: 'plus',
	plus_circle: 'plus-circle',
	plus_square: 'plus-square',
	plus_square_o: 'plus-square-o',
	podcast: 'podcast',
	power_off: 'power-off',
	print: 'print',
	product_hunt: 'product-hunt',
	puzzle_piece: 'puzzle-piece',
	qq: 'qq',
	qrcode: 'qrcode',
	question: 'question',
	question_circle: 'question-circle',
	question_circle_o: 'question-circle-o',
	quora: 'quora',
	quote_left: 'quote-left',
	quote_right: 'quote-right',
	ra: 'ra',
	random: 'random',
	ravelry: 'ravelry',
	rebel: 'rebel',
	recycle: 'recycle',
	reddit: 'reddit',
	reddit_alien: 'reddit-alien',
	reddit_square: 'reddit-square',
	refresh: 'refresh',
	registered: 'registered',
	remove: 'remove',
	renren: 'renren',
	reorder: 'reorder',
	repeat: 'repeat',
	reply: 'reply',
	reply_all: 'reply-all',
	resistance: 'resistance',
	retweet: 'retweet',
	rmb: 'rmb',
	road: 'road',
	rocket: 'rocket',
	rotate_left: 'rotate-left',
	rotate_right: 'rotate-right',
	rouble: 'rouble',
	rss: 'rss',
	rss_square: 'rss-square',
	rub: 'rub',
	ruble: 'ruble',
	rupee: 'rupee',
	s15: 's15',
	safari: 'safari',
	save: 'save',
	scissors: 'scissors',
	scribd: 'scribd',
	search: 'search',
	search_minus: 'search-minus',
	search_plus: 'search-plus',
	sellsy: 'sellsy',
	send: 'send',
	send_o: 'send-o',
	server: 'server',
	share: 'share',
	share_alt: 'share-alt',
	share_alt_square: 'share-alt-square',
	share_square: 'share-square',
	share_square_o: 'share-square-o',
	shekel: 'shekel',
	sheqel: 'sheqel',
	shield: 'shield',
	ship: 'ship',
	shirtsinbulk: 'shirtsinbulk',
	shopping_bag: 'shopping-bag',
	shopping_basket: 'shopping-basket',
	shopping_cart: 'shopping-cart',
	shower: 'shower',
	sign_in: 'sign-in',
	sign_language: 'sign-language',
	sign_out: 'sign-out',
	signal: 'signal',
	signing: 'signing',
	simplybuilt: 'simplybuilt',
	sitemap: 'sitemap',
	skyatlas: 'skyatlas',
	skype: 'skype',
	slack: 'slack',
	sliders: 'sliders',
	slideshare: 'slideshare',
	smile_o: 'smile-o',
	snapchat: 'snapchat',
	snapchat_ghost: 'snapchat-ghost',
	snapchat_square: 'snapchat-square',
	snowflake_o: 'snowflake-o',
	soccer_ball_o: 'soccer-ball-o',
	sort: 'sort',
	sort_alpha_asc: 'sort-alpha-asc',
	sort_alpha_desc: 'sort-alpha-desc',
	sort_amount_asc: 'sort-amount-asc',
	sort_amount_desc: 'sort-amount-desc',
	sort_asc: 'sort-asc',
	sort_desc: 'sort-desc',
	sort_down: 'sort-down',
	sort_numeric_asc: 'sort-numeric-asc',
	sort_numeric_desc: 'sort-numeric-desc',
	sort_up: 'sort-up',
	soundcloud: 'soundcloud',
	space_shuttle: 'space-shuttle',
	spinner: 'spinner',
	spoon: 'spoon',
	spotify: 'spotify',
	square: 'square',
	square_o: 'square-o',
	stack_exchange: 'stack-exchange',
	stack_overflow: 'stack-overflow',
	star: 'star',
	star_half: 'star-half',
	star_half_empty: 'star-half-empty',
	star_half_full: 'star-half-full',
	star_half_o: 'star-half-o',
	star_o: 'star-o',
	steam: 'steam',
	steam_square: 'steam-square',
	step_backward: 'step-backward',
	step_forward: 'step-forward',
	stethoscope: 'stethoscope',
	sticky_note: 'sticky-note',
	sticky_note_o: 'sticky-note-o',
	stop: 'stop',
	stop_circle: 'stop-circle',
	stop_circle_o: 'stop-circle-o',
	street_view: 'street-view',
	strikethrough: 'strikethrough',
	stumbleupon: 'stumbleupon',
	stumbleupon_circle: 'stumbleupon-circle',
	subscript: 'subscript',
	subway: 'subway',
	suitcase: 'suitcase',
	sun_o: 'sun-o',
	superpowers: 'superpowers',
	superscript: 'superscript',
	support: 'support',
	table: 'table',
	tablet: 'tablet',
	tachometer: 'tachometer',
	tag: 'tag',
	tags: 'tags',
	tasks: 'tasks',
	taxi: 'taxi',
	telegram: 'telegram',
	television: 'television',
	tencent_weibo: 'tencent-weibo',
	terminal: 'terminal',
	text_height: 'text-height',
	text_width: 'text-width',
	th: 'th',
	th_large: 'th-large',
	th_list: 'th-list',
	themeisle: 'themeisle',
	thermometer: 'thermometer',
	thermometer_0: 'thermometer-0',
	thermometer_1: 'thermometer-1',
	thermometer_2: 'thermometer-2',
	thermometer_3: 'thermometer-3',
	thermometer_4: 'thermometer-4',
	thermometer_empty: 'thermometer-empty',
	thermometer_full: 'thermometer-full',
	thermometer_half: 'thermometer-half',
	thermometer_quarter: 'thermometer-quarter',
	thermometer_three_quarters: 'thermometer-three-quarters',
	thumb_tack: 'thumb-tack',
	thumbs_down: 'thumbs-down',
	thumbs_o_down: 'thumbs-o-down',
	thumbs_o_up: 'thumbs-o-up',
	thumbs_up: 'thumbs-up',
	ticket: 'ticket',
	times: 'times',
	times_circle: 'times-circle',
	times_circle_o: 'times-circle-o',
	times_rectangle: 'times-rectangle',
	times_rectangle_o: 'times-rectangle-o',
	tint: 'tint',
	toggle_down: 'toggle-down',
	toggle_left: 'toggle-left',
	toggle_off: 'toggle-off',
	toggle_on: 'toggle-on',
	toggle_right: 'toggle-right',
	toggle_up: 'toggle-up',
	trademark: 'trademark',
	train: 'train',
	transgender: 'transgender',
	transgender_alt: 'transgender-alt',
	trash: 'trash',
	trash_o: 'trash-o',
	tree: 'tree',
	trello: 'trello',
	tripadvisor: 'tripadvisor',
	trophy: 'trophy',
	truck: 'truck',
	try: 'try',
	tty: 'tty',
	tumblr: 'tumblr',
	tumblr_square: 'tumblr-square',
	turkish_lira: 'turkish-lira',
	tv: 'tv',
	twitch: 'twitch',
	twitter: 'twitter',
	twitter_square: 'twitter-square',
	umbrella: 'umbrella',
	underline: 'underline',
	undo: 'undo',
	universal_access: 'universal-access',
	university: 'university',
	unlink: 'unlink',
	unlock: 'unlock',
	unlock_alt: 'unlock-alt',
	unsorted: 'unsorted',
	upload: 'upload',
	usb: 'usb',
	usd: 'usd',
	user: 'user',
	user_circle: 'user-circle',
	user_circle_o: 'user-circle-o',
	user_md: 'user-md',
	user_o: 'user-o',
	user_plus: 'user-plus',
	user_secret: 'user-secret',
	user_times: 'user-times',
	users: 'users',
	vcard: 'vcard',
	vcard_o: 'vcard-o',
	venus: 'venus',
	venus_double: 'venus-double',
	venus_mars: 'venus-mars',
	viacoin: 'viacoin',
	viadeo: 'viadeo',
	viadeo_square: 'viadeo-square',
	video_camera: 'video-camera',
	vimeo: 'vimeo',
	vimeo_square: 'vimeo-square',
	vine: 'vine',
	vk: 'vk',
	volume_control_phone: 'volume-control-phone',
	volume_down: 'volume-down',
	volume_off: 'volume-off',
	volume_up: 'volume-up',
	warning: 'warning',
	wechat: 'wechat',
	weibo: 'weibo',
	weixin: 'weixin',
	whatsapp: 'whatsapp',
	wheelchair: 'wheelchair',
	wheelchair_alt: 'wheelchair-alt',
	wifi: 'wifi',
	wikipedia_w: 'wikipedia-w',
	window_close: 'window-close',
	window_close_o: 'window-close-o',
	window_maximize: 'window-maximize',
	window_minimize: 'window-minimize',
	window_restore: 'window-restore',
	windows: 'windows',
	won: 'won',
	wordpress: 'wordpress',
	wpbeginner: 'wpbeginner',
	wpexplorer: 'wpexplorer',
	wpforms: 'wpforms',
	wrench: 'wrench',
	xing: 'xing',
	xing_square: 'xing-square',
	y_combinator: 'y-combinator',
	y_combinator_square: 'y-combinator-square',
	yahoo: 'yahoo',
	yc: 'yc',
	yc_square: 'yc-square',
	yelp: 'yelp',
	yen: 'yen',
	yoast: 'yoast',
	youtube: 'youtube',
	youtube_play: 'youtube-play',
	youtube_square: 'youtube-square',
};

export default FA;
