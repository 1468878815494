export const INIT_AUTH_START = 'INIT_AUTH_START';
export const INIT_AUTH_SUCCESS = 'INIT_AUTH_SUCCESS';
export const INIT_AUTH_ERROR = 'INIT_AUTH_ERROR';

export const SIGN_IN_START = 'SIGN_IN_START';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_ERROR = 'SIGN_IN_ERROR';

export const SIGN_OUT_START = 'SIGN_OUT_START';
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';
export const SIGN_OUT_ERROR = 'SIGN_OUT_ERROR';
